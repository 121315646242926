import { createServer, Model } from "miragejs"
import { M15, M15A, M15D, M15I, M18, M20, M18A, M18D, M18I, M20A, M20F, M28, M28A, M28D, M28I, M30R, M30R01, ORJE, PVC01, PVC02, PVC03 } from "./assets";

export function makeServer({ environment = "development" } = {}) {
    let server = createServer({
        environment,

        models: {
            products: Model,
        },

        seeds(server) {
            server.create("product", { id: "caseta-vigilancia-personal-vista-panoramica", title: "M15", details: ["Altura: 2,35 mts ", "Ancho: 1,20 mts", "Fondo: 1,50 mts", "Capacidad: 1,80 mts²"], features: ["Vista panorámica", "Opción de bodega", "Opción de baño", "Aire acondicionado"], type: "0", img: M15, images: [M15A, M15D, M15I] })
            server.create("product", { id: "caseta-vigilancia-vista-panoramica-modulo-amplio", title: "M18", details: ["Altura: 2,35 mts ", "Ancho: 1,80 mts", "Fondo: 1,50 mts", "Capacidad: 2,70 mts²"], features: ["Vista panorámica", "Opción de bodega", "Opción de baño", "Aire acondicionado"], type: "0", img: M18, images: [M18A, M18D, M18I] })
            server.create("product", { id: "caseta-vigilancia-vista-panoramica-modulo-extendido", title: "M20", details: ["Altura: 2,35 mts ", "Ancho: 2,40 mts", "Fondo: 1,50 mts", "Capacidad: 3,60 mts²"], features: ["Vista panorámica", "Opción de bodega", "Opción de baño", "Aire acondicionado"], type: "0", img: M20, images: [M20A, M20F] })
            server.create("product", { id: "caseta-vigilancia-vista-panoramica-multimodo", title: "M28", details: ["Altura: 2,35 mts ", "Ancho: 3,18 mts", "Fondo: 1,50 mts", "Capacidad: 4,77 mts²"], features: ["Vista panorámica", "Opción de bodega", "Opción de baño", "Aire acondicionado"], type: "1", img: M28, images: [M28A, M28D, M28I] })
            server.create("product", { id: "modulo-atencion-ventas", title: "M30R", details: ["Altura: Customizable ", "Ancho: Customizable", "Fondo: Customizable", "Capacidad: Customizable"], features: ["Modulo customizable", "Pintura especial", "Interiores de madera"], type: "2", img: M30R, images: [M30R01] })
            server.create("product", { id: "modulo-especial", title: "Proyectos especiales", details: ["Altura: Customizable ", "Ancho: Customizable", "Fondo: Customizable", "Capacidad: Customizable"], features: ["Color personalizado", "Interior PVC", "Opción en madera"], type: "2", img: ORJE, images: [PVC01, PVC02, PVC03] })
        },

        routes() {
            this.namespace = "api"

            this.get("/products", (schema) => {
                return schema.products.all()
            })

            this.get("/products/:id", (schema, request) => {
                const id = request.params.id
                return schema.products.find(id)
            })
        },
    })

    return server;
}