import React from "react";
import styles, { layout } from "../style";
import { delay, motion } from "framer-motion";

const animationVariants = {
  initial: { opacity: 0, y: 100 },
  animate: { opacity: 1, y: 0, transition: { delay: 0.15 } },
};

const Stats = () => (
  <section id="stats" className={`flex md:flex-row flex-col`}>
    <motion.div
      className={`flex ${styles.flexStart} flex-col xl:px-0 sm:px-16 px-6 `}
      variants={animationVariants}
      initial="initial"
      whileInView="animate"
      viewport={{
        once: true,
      }}
    >
      <div className="flex flex-col justify-between m-4 w-full">
        <h2 className="font-poppins font-normal xs:text-[36px] text-[36px] text-dimBlack xs:leading-[36.8px] leading-[36.8px] mb-4">
          Nuestros clientes
        </h2>
        <p className={styles.paragraph}>
          Nuestra empresa incorpora en sus productos los más altos estándares de
          calidad, siendo avalados por más de 1000 clientes satisfechos y sobre
          3000 proyectos entregados. <br />
          <br />
          Nuestros clientes incluyen:
        </p>
      </div>
    </motion.div>
  </section>
);

export default Stats;
