import styles from "../style";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const animationVariants = {
  initial: { opacity: 0, y: 100 },
  whileInView: { opacity: 1, y: 0 }
};

const Offer = () => {
  return (
    <section
      id="offer"
      className={`flex ss:flex-row flex-col ${styles.paddingY} ${styles.paddingX} rounded-2xl`}
    >
      <motion.div
        className={`flex flex-col ${styles.flexStart}`}
        variants={animationVariants}
        initial="initial"
        whileInView="whileInView"
        viewport={{
          once: true,
        }}
      >
        <div className="flex flex-col justify-between m-4 w-full">
          <h2 className="font-poppins font-normal xs:text-[36px] text-[36px] text-dimBlack xs:leading-[36.8px] leading-[36.8px] mb-4">
            Nuestros productos
          </h2>
          <p
            className={`flex-1 ${styles.paragraph} text-justify max-sm:hidden`}
          >
            Revise nuestro{" "}
            <Link to="/productos" className="font-semibold text-amber-500">
              catálogo
            </Link>{" "}
            y elija entre diversos tipos de soluciones según sean sus
            necesidades. Ofrecemos oficinas portátiles, módulos con vista
            panorámica, módulos ampliados multipropósito, módulos con baño e
            instalación elécrica incorporada, casetas con sistema de
            climatización, bodegas con repiza o módulos especiales de diversas
            dimensiones. En Fibermold tenemos una solución para usted.
          </p>
        </div>
      </motion.div>
    </section>
  );
};

export default Offer;
