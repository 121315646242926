import { useState, useEffect } from "react";
import styles from "../style";
import CatalogCard from "./CatalogCard";

import { makeServer } from "../server";

if (process.env.NODE_ENV === "development") {
  makeServer({ environment: "development" });
} else {
  makeServer({ environment: "production" });
}

const Products = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    fetch("/api/products")
      .then((res) => res.json())
      .then((data) => setProducts(data.products));
  }, []);

  return (
    <section id="products" className={styles.flexCenter}>
      <div className="flex flex-col m-8 justify-start items-start">
        <div className="flex-1 flex-row m-8 justify-center items-center">
          <div>
            <h2 className="font-poppins font-normal xs:text-[36px] text-[36px] text-dimBlack xs:leading-[36.8px] leading-[36.8px]">
              Módulos simples
            </h2>
          </div>
          <div className="flex flex-wrap sm:justify-start justify-center w-full catalog-container z-[1] m-4 slidedown">
            {products
              .filter((product) => product.type == "0")
              .map((filteredProduct, index) => (
                <CatalogCard key={filteredProduct.id} {...filteredProduct} index={index}/>
              ))}
          </div>
        </div>
        <hr />
        <div className="flex-1 flex-row m-8 justify-center items-center">
          <div>
            <h2 className="font-poppins font-normal xs:text-[36px] text-[36px] text-dimBlack xs:leading-[36.8px] leading-[36.8px]">
              Módulos ampliables
            </h2>
          </div>
          <div className="flex flex-wrap sm:justify-start justify-center w-full catalog-container z-[1] m-4">
            {products
              .filter((product) => product.type == "1")
              .map((filteredProduct) => (
                <CatalogCard key={filteredProduct.id} {...filteredProduct} />
              ))}
          </div>
          <div>
            <h2 className="font-poppins font-normal xs:text-[36px] text-[36px] text-dimBlack xs:leading-[36.8px] leading-[36.8px]">
              Proyectos especiales
            </h2>
          </div>
          <div className="flex flex-wrap sm:justify-start justify-center w-full catalog-container z-[1] m-4">
            {products
              .filter((product) => product.type == "2")
              .map((filteredProduct) => (
                <CatalogCard key={filteredProduct.id} {...filteredProduct} />
              ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Products;
