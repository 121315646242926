import { useForm } from "react-hook-form";
import styles, { layout } from "../style";
import { delay, motion } from "framer-motion";

const animationVariants = {
  initial: { opacity: 0, y: 100 },
  animate: { opacity: 1, y: 0, transition: { delay: 0.15 } },
};

const ContactForm = () => {
  const {
    register,
    trigger,
    formState: { errors },
  } = useForm();

  const onSubmit = async (e) => {
    const isValid = await trigger();
    if (!isValid) {
      e.preventDefault();
    }
  };

  return (
    <motion.section
      id="contactForm"
      className={`${styles.paddingX} ${styles.flexCenter} m-4`}
      variants={animationVariants}
      initial="initial"
      whileInView="animate"
      viewport={{
        once: true,
      }}
    >
      <div
        className={`flex-1 ${styles.flexCenter} flex-col xl:px-0 sm:px-16 px-6`}
      >
        <h2 className={styles.heading2}>Escríbanos</h2>
        <p className={`${styles.paragraph} m-4`}>
          y un ejecutivo se pondrá en contacto con usted
        </p>
        <form
          target="_blank"
          onSubmit={onSubmit}
          action="https://formsubmit.co/andres.jorquera@fibermold.cl"
          method="POST"
        >
          <input type="hidden" name="_captcha" value="false" />
          <input
            type="hidden"
            name="_next"
            value="https://fibermold.cl/enviado"
          />
          <input
            className="w-full bg-blue font-semibold placeholder-opaque-black p-3"
            type="text"
            placeholder="Nombre"
            {...register("name", {
              required: true,
              maxLength: 40,
            })}
          />
          {errors.name && (
            <p>
              {errors.name.type === "required" && "Este campo es obligatorio."}
              {errors.name.type === "maxLength" &&
                "Este campo no debe exceder 40 caracteres."}
            </p>
          )}

          <input
            className="w-full bg-blue font-semibold placeholder-opaque-black p-3 mt-5"
            type="text"
            placeholder="Email"
            {...register("email", {
              required: true,
              pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            })}
          />
          {errors.email && (
            <p>
              {errors.name.type === "required" && "Este campo es obligatorio."}
              {errors.name.type === "pattern" &&
                "Dirección de correo inválida."}
            </p>
          )}
          <input
            className="w-full bg-blue font-semibold placeholder-opaque-black p-3 mt-5"
            type="text"
            placeholder="Teléfono"
            {...register("fono", {
              required: false,
              maxLength: 20,
            })}
          />
          {errors.fono && (
            <p>
              {errors.name.type === "maxLength" &&
                "Este campo no debe exceder 20 caracteres."}
            </p>
          )}
          <textarea
            className="w-full bg-blue font-semibold placeholder-opaque-black p-3 mt-5"
            type="text"
            placeholder="Mensaje"
            rows="4"
            cols="50"
            {...register("message", {
              required: true,
              maxLength: 3500,
            })}
          />
          {errors.message && (
            <p>
              {errors.name.type === "required" && "Este campo es obligatorio."}
              {errors.name.type === "maxLength" && "mucho texto"}
            </p>
          )}
          <div
            className={`flex-1 ${styles.flexCenter} flex-col xl:px-0 sm:p-16 p-6`}
          >
            <button
              type="submit"
              className={`text-primary bg-amber-500 p-6 min-w-[200px] font-poppins font-medium text[-18px] outline-none rounded-[100px]`}
            >
              Enviar
            </button>
          </div>
        </form>
      </div>
    </motion.section>
  );
};

export default ContactForm;
