import {
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  Route
} from "react-router-dom";
import {
  Layout,
  Home,
  About,
  Products,
  Product,
  Map,
  ContactForm,
  Success,
  NotFound,
} from "./components";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />}>
      <Route index element={<Home />} />
      <Route path="home" element={<Home />} />
      <Route path="nosotros" element={<About />} />
      <Route path="productos" element={<Products />} />
      <Route path="productos/:id" element={<Product />} />
      <Route path="mapa" element={<Map />} />
      <Route path="contacto" element={<ContactForm />} />
      <Route path="enviado" element={<Success />} />
      <Route path="*" element={<NotFound />} />
    </Route>
  )
);

const App = () => {
  return <RouterProvider router={router} />
};

export default App;
