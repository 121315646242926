import styles from "../style";
import Button from "./Button";
import { portadax1, portada, portada1, portada2, portada3, moldy } from "../assets";
import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";
import { delay, motion } from "framer-motion";

const animationVariants = {
  initial: { opacity: 0, x: 100 },
  animate: { opacity: 1, x: 0, transition: { delay: 0.15 } },
};

const textVariants = {
  initial: { opacity: 0, x: -100 },
  animate: { opacity: 1, x: 0, transition: { delay: 0.15 } },
};

const Hero = () => {
  const autoplayOptions = {
    delay: 4000,
  };

  const [emblaRef] = useEmblaCarousel({ loop: true }, [
    Autoplay(autoplayOptions),
  ]);

  return (
    <section id="hero" className="grid grid-cols-1 md:grid-cols-2">
      <motion.div
        className={`flex flex-col justify-center pl-4`}
        variants={animationVariants}
        initial="initial"
        whileInView="animate"
        viewport={{
          once: true,
        }}
      >
        <div className="flex flex-col justify-between">
          <h1 className="flex font-montserrat font-bold ss:text-[72px] text-[48px] text-zinc-500 ss:leading-[75px] leading-[56px] mt-2">
            Fibermold
          </h1>
          <h2 className="flex-1 font-poppins font-normal ss:text-[48px] text-[24px] text-black ss:leading-[48px] leading-[32px]">
            Soluciones modulares
            <br />
            <span className="text-amber-500">en fibra de vidrio</span>
          </h2>
        </div>
        <p className={`ss:flex hidden ${styles.paragraph} max-w-[470px] m-2`}>
          Casetas de vigilancia de alta resistencia
        </p>
      </motion.div>
      <div className="">
        <img
          src={portadax1}
          alt="caseta"
          className="md:flex hidden flex-row justify-between"
        />
      </div>
      <div className="embla" ref={emblaRef}>
        <div className="embla__container">
          <div className="embla__slide">
            <img src={portada} alt="caseta1" />
          </div>
          <div className="embla__slide">
            <img src={portada1} alt="caseta2" />
          </div>
          <div className="embla__slide">
            <img src={portada2} alt="caseta3" />
          </div>
          <div className="embla__slide">
            <img src={portada3} alt="caseta4" />
          </div>
        </div>
      </div>
      <motion.div className={`md:flex hidden ${styles.flexStart} flex-col`}
      variants={textVariants}
        initial="initial"
        whileInView="animate"
        viewport={{
          once: true,
        }}>
        <div className={`flex-1 ${styles.flexCenter} flex-col`}>
          <p className={`${styles.paragraph} m-8 md:text-justify`}>
            <ins>Pioneros en producción modular,</ins> en Fibermold contamos con
            una amplia gama de soluciones que se ajustan a cualquier tipo de
            necesidad. Ya sea casetas de vigilancia, bodegas o puntos de
            atención, en <strong>Fibermold</strong> tenemos las herramientas
            necesarias para ofrecer una solución satisfactoria que cumpla con
            los más altos estandares de la industria.
          </p>
          <div className="flex flex-col sm:flex-row sm:items-center m-2">
            <Button
              styles="my-6 mr-6 text-primary bg-amber-500"
              title="Contactenos"
              to="contacto"
            />
            <Button
              styles="my-0 border-2 bg-white"
              title="Empresa"
              to="nosotros"
            />
          </div>
        </div>
      </motion.div>
    </section>
  );
};

export default Hero;
