import { useState } from "react";
import styles from "../style";
import { close, logo, menu } from "../assets";
import { navLinks } from "../constants";
import { NavLink } from "react-router-dom";

const Navbar = () => {
  const [toggle, setToggle] = useState(false);

  return (
    <nav className="flex bg-primary w-full border-b border-amber-500 p-2">
      <img src={logo} alt="fibermold" className="max-w-[128px]" />
      <ul className="list-none md:flex hidden justify-end items-center flex-1 gap-1 mr-4">
        {navLinks.map((nav, index) => (
          <li
            key={nav.id}
            className={`text-zinc-500 font-poppins font-normal cursor-pointer text-[16px] ${
              index === navLinks.length - 1 ? "mr-0" : "mr-10"
            }`}
          >
            <NavLink
              to={nav.id}
              className={({ isActive }) => (isActive ? styles.activeLink : "")}
            >
              {nav.title}
            </NavLink>
          </li>
        ))}
      </ul>
      <div className="md:hidden flex flex-1 justify-end items-center">
        <img
          src={toggle ? close : menu}
          alt="menu"
          className="w-[28px] h-[28px] object-contain"
          onClick={() => setToggle((prev) => !prev)}
        />

        <div
          className={`${
            toggle ? "flex" : "hidden"
          } p-6 bg-yellow-gradient absolute top-20 right-0 mx-4 my-2 min-w-[140px] rounded-xl sidebar z-[5]`}
        >
          <ul className="list-none flex flex-col justify-end items-center flex-1">
            {navLinks.map((nav, index) => (
              <li
                key={nav.id}
                className={`font-poppins font-normal cursor-pointer text-[16px] ${
                  index === navLinks.length - 1 ? "mr-0" : "mb-4"
                }`}
              >
                <NavLink
                  to={nav.id}
                  className={({ isActive }) =>
                    isActive ? styles.activeLinkSmol : ""
                  }
                >
                  {nav.title}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
