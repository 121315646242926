import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const animationVariants = {
  initial: { opacity: 0, y: 100 },
  animate: (index) => ({
    opacity: 1,
    y: 0,
    transition: { delay: 0.05 * index },
  }),
};

const CatalogCard = ({ id, title, features, img, index }) => (
  <motion.div
    className="flex justify-between flex-col max-w-[340px] md:mr-10 sm:mr-5 mr-0 my-5 catalog-card bg-white"
    variants={animationVariants}
    initial="initial"
    whileInView="animate"
    viewport={{
      once: true,
    }}
    custom={index}
  >
    <div className="flex flex-col">
      <Link to={`/productos/${id}`}>
        <img src={img} alt={title} className="max-h-[456px] " />
      </Link>
      <div className="flex flex-col my-4">
        <h2 className="font-poppins font-normal	ml-4 text-[24px] leading-[24px] text-amber-500">
          {title}
        </h2>
        <ul className="flex flex-col m-4 mb-16 min-h-[112px] justify-start md:list-square list-none list-inside">
          {features.map((feat) => (
            <li key={features.key} className="m-2 font-semibold leading-[16px] text-[18px] text-dimBlack">
              {feat}
            </li>
          ))}
        </ul>
        <Link
          to={id ? id : "/productos"}
          className="flex self-center font-semibold leading-[16px] text-[20px] my-8 text-amber-500"
        >{`Más información >`}</Link>
      </div>
    </div>
  </motion.div>
);

export default CatalogCard;
