import styles, { layout } from "../style";
import { features } from "../constants";
import { delay, motion } from "framer-motion";

const animationVariants = {
  initial: { opacity: 0, x: 100 },
  animate: (index) => ({ 
    opacity: 1, 
    x: 0, 
    transition: { delay: 0.05 * index } }),
};

const FeatureCard = ({ icon, content, index }) => (
  <motion.div
    className={`flex flex-row ${
      index !== features.length - 1 ? "mb-0" : "mb-0"
    }`}
    variants={animationVariants}
    initial="initial"
    whileInView="animate"
    viewport={{
      once: true,
    }}
    custom={index}
  >
    <div className={`w-[48px] h-[48px] ${styles.flexCenter}`}>
      <img src={icon} alt="icon" className="w-[50%] h-[50%]" />
    </div>
    <p className={`font-poppins font-normal ${styles.flexCenter}`}>{content}</p>
  </motion.div>
);

const Features = () => (
  <section id="features" className={`hidden sm:flex ${layout.section}`}>
    <div
      className={`flex flex-row flex-wrap border border-amber-500 rounded-[50px] bg-white overflow-hidden p-4 m-4 justify-center`}
    >
      {features.map((feature, index) => (
        <FeatureCard key={feature.id} {...feature} index={index} />
      ))}
    </div>
  </section>
);

export default Features;
