import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import styles, { layout } from "../style";
import ImageGallery from 'react-image-gallery';
import Button from "./Button";
import 'react-image-gallery/styles/css/image-gallery.css';

const Product = () => {
  const { id } = useParams();
  const [currentProd, setCurrentProd] = useState(null);

  useEffect(() => {
    fetch(`/api/products/${id}`)
      .then((res) => res.json())
      .then((data) => setCurrentProd(data.products));
  }, []);

  if (!currentProd) {
    return <h1>Buscando...</h1>;
  }

  const images = [
    {
      original: currentProd.img,
      thumbnail: currentProd.img,
    }
  ];

  for(let ix=0; ix<currentProd.images.length; ix++){
    const photo = {
      original: currentProd.images[ix],
      thumbnail: currentProd.images[ix],
    }
    images.push(photo);
  };

  return (
     <section id="detalle" className="flex flex-col m-2 p-2">
      <Link
        to={`/productos`}
        className="flex font-poppins font-semibold text-black underline sm:p-6 p-2"
      >
        &larr; <span>Volver al catálogo</span>{" "}
      </Link>

      <div
        className="flex sm:flex-row flex-col xl:px-0 sm:px-16 px-6 overflow-auto justify-evenly"
      > 
        <ImageGallery items={images} />
        <div className="flex flex-col p-4 mx-2 sm:border-2 h-fit">
          <h2
            className="font-normal ss:text-[36px] text-[32px] text-black ss:leading-[36px] leading-[32px]"
          >
            Modelo: {currentProd.title}
          </h2>
          <div>
            <h2
              className="font-normal ss:text-[24px] text-[18px] text-amber-500 mt-5 ss:leading-[36px] leading-[32px]"
            >
              Medidas:
            </h2>
            {currentProd.details.map((detail) => (
              <div key={currentProd.details.key} className="flex flex-col mt-1">
                <p className={styles.paragraph}>{detail}</p>
              </div>
            ))}
            <h2
              className="font-normal ss:text-[24px] text-[18px] text-amber-500 mt-5 ss:leading-[36px] leading-[32px]"
            >
              Opciones:
            </h2>
            {currentProd.features.map((feat) => (
              <div
                key={currentProd.features.key}
                className="flex flex-col mt-1"
              >
                <p className={styles.paragraph}>{feat}</p>
              </div>
            ))}
          </div>
          <Button
          styles="mt-14 mr-6 text-primary bg-amber-500"
          title="Contactenos"
          to="contacto"
        />
        </div>
      </div>
    </section>
  );
};

export default Product;
