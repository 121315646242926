import styles from "../style";
import Clients from "./Clients";
import { motion } from "framer-motion"

const About = () => (
  <section id="about">
    <div
      className={`flex flex-col ${styles.paddingX} ${styles.paddingY}`}
    >
      <h1 className="flex font-poppins font-semibold ss:text-[72px] text-[48px] text-black ss:leading-[48px] leading-[36px]">
        Quiénes somos
      </h1>
      <p className={`${styles.paragraph} my-4 md:text-justify md:hidden`}>
        <strong>Nuestro compromiso</strong> es proporcionar soluciones de alta
        calidad. Ya sean casetas de vigilancia, oficinas portátiles, módulos de
        atención, bodegas o cualquier otro tipo de requerimiento; buscamos
        brindar un producto que garantice un espacio seguro para su usuario,
        siempre considerando durabilidad y funcionalidad.
      </p>
      <div className={`${styles.flexCenter} md:flex hidden`}>
        <iframe
          src="https://player.vimeo.com/video/539662071?h=daa3bb1c8a"
          width="1024"
          height="768"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
        />
      </div>
      <div className="md:flex hidden flex-col sm:flex-row">
        <p className={`${styles.paragraph} mx-9 indent-12 text-justify`}>
          <strong>Nuestro compromiso</strong> es proporcionar soluciones de alta
          calidad. Ya sean casetas de vigilancia, oficinas portátiles, módulos
          de atención, bodegas o cualquier otro tipo de requerimiento; buscamos
          brindar un producto que garantice un espacio seguro para su usuario,
          siempre considerando durabilidad y funcionalidad. Con más de 50 años
          de experiencia en el mercado, en Fibermold hemos priorizado el
          conocimiento y la experiencia de nuestro personal con el fin de dar
          una solución que satisfaga todas sus necesidades, brindar una amena
          experiencia y ofrecer asistencia y soporte a lo largo de nuestros
          proyectos. Trabajamos en estrecha colaboración con nuestros clientes
          para garantizar que el producto se adapte a sus requisitos
          específicos. Elíjanos para sus necesidades y experimente la
          tranquilidad de saber que cuenta con una solución de seguridad
          confiable.
        </p>
      </div>
    </div>
    <div>
      <h2 className="flex font-poppins font-normal ss:text-[48px] text-[24px] text-zinc-700 ss:leading-[48px] leading-[36px] justify-center items-center">
        Nuestros clientes incluyen:
      </h2>
    </div>
    <Clients />
  </section>
);

export default About;
