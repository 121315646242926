import styles from "../style";
import Hero from "./Hero";
import Catalog from "./Catalog";
import Features from "./Features";
import Contact from "./Contact";
import Stats from "./Stats";
import Clients from "./Clients";
import Offer from "./Offer";

const Home = () => (
  <div>
    <Hero />
    <hr />
    <div className={styles.flexCenter}>
      <div className={`${styles.boxWidth}`}>
        <Offer />
        <hr />
        <Catalog />
        <hr />
        <Features />
        <hr />
        <Contact />
        <hr />
      </div>
    </div>
    <div className={styles.flexStart}>
      <div className={`${styles.boxWidth}`}>
        <Stats />
        <Clients />
      </div>
    </div>
  </div>
);

export default Home;
