import React from "react";
import styles from "../style";

const Map = () => {
  return (
    <section
      id="ubicacion"
    >
      <div
        className={`flex-1  ${styles.paddingY} flex-col xl:px-0 sm:px-16 px-6 m-2`}
      >
        <div className={`flex ${styles.heading2} ${styles.flexStart}`}>
          <h2 className="flex-1 font-poppins font-normal ss:text-[36px] text-[32px] text-black ss:leading-[36px] leading-[32px]">
            Nos encontramos en:
          </h2>
        </div>
        <p className={`flex-1 ${styles.paragraph}`}>
          Cerro los Cóndores #9951, Quilicura
        </p>
        <div className={`flex-1 ${styles.paddingY} ${styles.flexCenter}`}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3333.5979407222703!2d-70.7265694!3d-33.3293252!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662c0a83a97de33%3A0xbc1df4e31ba116ae!2sCerro%20Los%20C%C3%B3ndores%209951%2C%208720009%20Quilicura%2C%20Regi%C3%B3n%20Metropolitana!5e0!3m2!1sen!2scl!4v1685754100293!5m2!1sen!2scl"
            width="1024"
            height="768"
            style={{ border: "0", overflow: "hidden" }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </section>
  );
};

export default Map;
