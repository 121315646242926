import { clients } from "../constants";
import styles, { layout } from "../style";
import { delay, motion } from "framer-motion";

const animationVariants = {
  initial: { opacity: 0, x: 100 },
  animate: (index) => ({
    opacity: 1,
    x: 0,
    transition: { delay: 0.05 * index },
  }),
};

const Clients = () => (
  <section
    id="clients"
    className={`flex md:flex-row flex-col ${styles.paddingY}`}
  >
    <div className={`${styles.flexCenter} flex-wrap w-full bg-white`}>
      {clients.map((client, index) => (
        <div
          key={client.id}
          className={`flex-1 ${styles.flexCenter} sm:min-w-[192px] min-w-[120px]`}
        >
          <motion.img
            src={client.logo}
            alt="client"
            className="sm:w-[192px] w-[100px] object-contain"
            variants={animationVariants}
            initial="initial"
            whileInView="animate"
            viewport={{
              once: true,
            }}
            custom={index}
          />
        </div>
      ))}
    </div>
  </section>
);

export default Clients;
