import { Link } from "react-router-dom";

const Button = ({ styles, title, to }) => {
  return (
    <Link to={`/${to}`}>
      <button
        type="button"
        className={`p-6 min-w-[196px] font-poppins font-medium text-[18px] outline-none rounded-[100px] ${styles}`}
      >
        {title}
      </button>
    </Link>
  );
};

export default Button;
