import styles from "../style";
import { footerLinks } from "../constants";
import { Link } from "react-router-dom";
import { delay, motion } from "framer-motion";

const animationVariants = {
  initial: { opacity: 0, y: 100 },
  animate: {
    opacity: 1,
    y: 0,
    transition: { delay: 0.1 },
  },
};

const Footer = () => (
  <motion.section
    className={`${styles.flexCenter} ${styles.paddingY} bg-zinc-800 flex-col`}
    variants={animationVariants}
    initial="initial"
    whileInView="animate"
    viewport={{
      once: true,
    }}
  >
    <div
      className={`${styles.flexStart} sm:flex-row mb-8 w-full`}

    >
      <div className="flex w-full sm:justify-around flex-wrap m-5">
        {footerLinks.map((footerLink) => (
          <div
            key={footerLink.key}
            className="flex flex-col my-3 min-w-[150px]"
          >
            <h4 className="font-poppins font-medium text-[24px] leading-[28px] text-white underline my-7">
              {footerLink.title}
            </h4>
            <ul className="list-none mt-2">
              {footerLink.links.map((link, index) => (
                <li
                  key={link.name}
                  className={`font-poppins font-normal text-[16px] leading-[24px] text-white hover:text-secondary cursor-pointer ${
                    index !== footerLink.links.length - 1 ? "mb-4" : "mb-0"
                  }`}
                >
                  <Link to={link.link}>{link.name}</Link>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
    <p className="justify-start font-poppins font-thin text-[12px] leading-[16px] text-white">
      Fibermold 2024© Todos los derechos reservados
    </p>
  </motion.section>
);

export default Footer;
