import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "../style";

const Success = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!document.referrer) navigate("/");
  }, []);

  return (
    <div
      className={`flex ${styles.flexCenter} ${styles.paddingY} flex-col h-screen`}
    >
      <h1 className={styles.heading2}>Formulario enviado</h1>
      <Link
        to="/"
        className="flex font-semibold ml-4 leading-[16px] text-[20px] my-8 text-amber-500"
      >
        Volver al inicio
      </Link>
    </div>
  );
};

export default Success;
