import styles from "../style";
import { Outlet, ScrollRestoration } from "react-router-dom";
import { Navbar, Footer } from "../components";

const Layout = () => {
  return (
    <div className={`${styles.paddingX} ${styles.flexCenter}`}>
      <div className={`bg-primary ${styles.boxWidth} border-x-amber-500 border`}>
        <Navbar />
        <Outlet />
        <Footer />
        <ScrollRestoration />
      </div>
    </div>
  );
};

export default Layout;
