import styles, { layout } from "../style";
import { callin, location, call, mail, schedule } from "../assets";
import Button from "./Button";
import { delay, motion } from "framer-motion";

const animationVariants = {
  initial: { opacity: 0, x: 100 },
  animate: { opacity: 1, x: 0, transition: { delay: 0.15 } },
};

const imageVariants = {
  initial: { opacity: 0, x: -100 },
  animate: { opacity: 1, x: 0, transition: { delay: 0.15 } },
};

const Contact = () => (
  <section id="contact" className={`${layout.section} m-4`}>
    <motion.div
      className={`${layout.sectionInfo} mx-4 px-4`}
      variants={animationVariants}
      initial="initial"
      whileInView="animate"
      viewport={{
        once: true,
      }}
    >
      <h2 className={styles.heading2}>
        Solicite un ejecutivo
        <br /> de <span className="text-amber-500">ventas</span>
      </h2>
      <div className="flex sm:hidden">
        <ul className={styles.paddingY}>
          <li className={styles.paragraph}>
            • Cerro los Cóndores #9951, Quilicura
          </li>
          <li className={styles.paragraph}>• +56 9 4269 6700</li>
          <li className={styles.paragraph}>• ventas@fibermold.cl</li>
          <li className={styles.paragraph}>• 09:00 a 19:00 Hrs</li>
        </ul>
      </div>
      <div className="hidden sm:flex flex-1 md:justify-center mt-6 items-start flex-col">
        <p
          className={`font-poppins font-thin ${styles.flexStart} sm:text-[22px] text-[16px] text-black sm:leading-[22px] leading-[20px]`}
        >
          <div className={`w-[64px] h-[64px] ${styles.flexStart}`}>
            <img src={location} alt="icon" className="w-[40%] h-[40%]" />
          </div>
          Cerro los Cóndores #9951, Quilicura
        </p>
        <p
          className={`font-poppins font-normal ${styles.flexStart} sm:text-[22px] text-[16px] text-black sm:leading-[22px] leading-[20px]`}
        >
          <div className={`w-[64px] h-[64px] ${styles.flexStart}`}>
            <img src={call} alt="icon" className="w-[40%] h-[40%]" />
          </div>
          +56 9 4269 6700
        </p>
        <p
          className={`font-poppins font-normal ${styles.flexStart} sm:text-[22px] text-[16px] text-black sm:leading-[22px] leading-[20px]`}
        >
          <div className={`w-[64px] h-[64px] ${styles.flexStart}`}>
            <img src={mail} alt="icon" className="w-[40%] h-[40%]" />
          </div>
          ventas@fibermold.cl
        </p>
        <p
          className={`font-poppins font-normal ${styles.flexStart} sm:text-[22px] text-[16px] text-black sm:leading-[22px] leading-[20px]`}
        >
          <div className={`w-[64px] h-[64px] ${styles.flexStart}`}>
            <img src={schedule} alt="icon" className="w-[40%] h-[40%]" />
          </div>
          09:00 a 19:00 Hrs
        </p>
      </div>
      <div className="flex-1 flex-col sm:flex-row">
        <Button
          styles="my-0 mr-6 text-primary bg-amber-500"
          title="Contactenos"
          to="contacto"
        />
        <Button
          styles="my-10 border-2 bg-white"
          title="Productos"
          to="productos"
        />
      </div>
    </motion.div>
    <motion.img
      src={callin}
      alt="contact"
      className={`md:flex hidden w-[25%] h-[25%] relative z-[5]`}
      variants={imageVariants}
      initial="initial"
      whileInView="animate"
      viewport={{
        once: true,
      }}
    />
  </section>
);

export default Contact;
