import {
  M15,
  M20,
  M30R,
  zublin,
  sansebastian,
  volvo,
  bechtel,
  mpc,
  nestle,
  blumar,
  banff,
  arauco,
  cmpc,
  alstom,
  aerovan,
  star,
} from "../assets";

export const navLinks = [
  {
    id: "home",
    title: "Home",
  },
  {
    id: "nosotros",
    title: "Nosotros",
  },
  {
    id: "productos",
    title: "Productos",
  },
  {
    id: "contacto",
    title: "Contacto",
  },
  {
    id: "mapa",
    title: "Ubicación",
  },
];

export const models = [
  {
    id: "",
    title: "Casetas de vigilancia",
    features: [
      "Casetas para portería",
      "Espacios modificables",
      "Opción de baño",
      "Aire acondicionado",
    ],
    img: M15,
  },
  {
    id: "",
    title: "Módulos ampliados",
    features: [
      "Vista panorámica de 360º",
      "Amplia capacidad multiuso",
      "Opción de baño",
      "Aire acondicionado",
    ],
    img: M20,
  },
  {
    id: "",
    title: "Proyectos especiales",
    features: [
      "Casetas multipropósito",
      "Diseños personalizados",
    ],
    img: M30R,
  },
];

export const features = [
  {
    id: "feature-1",
    icon: star,
    title: "laminado",
    content: "Laminado de alta resistencia",
  },
  {
    id: "feature-2",
    icon: star,
    title: "pintura",
    content: "Pintura plástica incorporada",
  },
  {
    id: "feature-3",
    icon: star,
    title: "electricidad",
    content: "Sistema eléctrico incorporado",
  },
  {
    id: "feature-4",
    icon: star,
    title: "ventilacion",
    content: "Ventilación incluída",
  },
  {
    id: "feature-5",
    icon: star,
    title: "guantera",
    content: "Muebles adosados con guanteras",
  },
  {
    id: "feature-6",
    icon: star,
    title: "repisa",
    content: "Repisas perimetrales",
  },
  {
    id: "feature-7",
    icon: star,
    title: "puerta",
    content: "Puerta con llave",
  },
  {
    id: "feature-8",
    icon: star,
    title: "PRFV",
    content: "Piso PRFV - anticorrosivo",
  },
  {
    id: "feature-9",
    icon: star,
    title: "ventana",
    content: "Ventana corredera de aluminio",
  },
];

export const stats = [
  {
    id: "stats-1",
    title: "Clientes satisfechos",
    value: "1353+",
  },
  {
    id: "stats-2",
    title: "Cabinas en empresas",
    value: "3645+",
  },
];

export const footerLinks = [
  {
    title: "Información",
    links: [
      {
        name: "+56 9 4269 6700",
        link: "#",
      },
      {
        name: "ventas@fibermold.cl",
        link: "mailto:ventas@fibermold.cl",
      },
      {
        name: "Cerro los Cóndores #9951, Quilicura",
        link: "/mapa/",
      },
    ],
  },
  {
    title: "Menú",
    links: [
      {
        name: "Quienes somos",
        link: "/nosotros/",
      },
      {
        name: "Productos",
        link: "/productos/",
      },

    ],
  },
];

export const clients = [
  {
    id: "client-1",
    logo: aerovan,
  },
  {
    id: "client-2",
    logo: alstom,
  },
  {
    id: "client-3",
    logo: arauco,
  },
  {
    id: "client-4",
    logo: banff,
  },
  {
    id: "client-5",
    logo: bechtel,
  },
  {
    id: "client-6",
    logo: blumar,
  },
  {
    id: "client-7",
    logo: cmpc,
  },
  {
    id: "client-8",
    logo: mpc,
  },
  {
    id: "client-9",
    logo: nestle,
  },
  {
    id: "client-10",
    logo: zublin,
  },
  {
    id: "client-11",
    logo: sansebastian,
  },
  {
    id: "client-12",
    logo: volvo,
  },
];
