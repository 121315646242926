import { models } from "../constants";
import styles from "../style";
import CatalogCard from "./CatalogCard";
import { motion } from "framer-motion";

const animationVariants = {
  initial: { opacity: 0, x: 100 },
  animate: { opacity: 1, x: 0, transition: { delay: 0.15 } },
};

const Catalog = () => (
  <section id="catalog" className={`${styles.flexCenter} relative`}>
    <motion.div
      className="flex flex-wrap justify-center w-auto catalog-container z-[1] px-4"
      variants={animationVariants}
      initial="initial"
      whileInView="animate"
      viewport={{
        once: true,
      }}
    >
      {models.map((model) => (
        <CatalogCard key={model.id} {...model} />
      ))}
    </motion.div>
  </section>
);

export default Catalog;
