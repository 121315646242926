import React from 'react'
import { Link } from 'react-router-dom'
import styles from '../style'

const NotFound = () => {
  return (
    <div className={`flex ${styles.flexCenter} ${styles.paddingY} flex-col min-h-screen`}>
        <h1 className={styles.heading2}>Pagina no encontrada</h1>
        <Link to="/" className="flex font-semibold ml-4 leading-[16px] text-[20px] my-8 text-amber-500">Volver al inicio</Link>
    </div>
  )
}

export default NotFound